import React, { useEffect, useState } from 'react';
import ProjectSelector from '@/components/navigation/ProjectSelector';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';
import { setProjectId } from '@/store/project';
import { useDispatch } from 'react-redux';
import { Toolbar } from '@mui/material';
import { Drawer, DrawerBody, DrawerHeader } from './styles/nav.styled';
import {
  IconArrowBack,
  IconArrowForward,
  IconMenu
} from '@mzc-cloudplex/icons';
import { IconButton } from '@mzc-cloudplex/core';
import { Box } from '@mui/system';
import NavList from '@/components/navigation/NavList';
import useMenu from '@/components/navigation/hooks/useMenu';

const Navigator = () => {
  const dispatch = useDispatch();
  const [isIconShow, setIsIconShown] = useState(false);
  const { spaceId, projectId } = useDeliveryLocation();
  const { menus } = useMenu({ spaceId: spaceId, projectId: projectId });

  useEffect(() => {
    if (projectId !== '') {
      dispatch(setProjectId({ projectId }));
    }
  }, [projectId]);

  const [isExpanded, setExpanded] = useState(true);

  const handleDrawerToggle = () => {
    setExpanded(!isExpanded);
  };

  const getNavToggleIcon = () => {
    if (isExpanded) return <IconArrowBack size={12} htmlColor={`#fff`} />;
    if (isIconShow) return <IconArrowForward size={12} htmlColor={`#fff`} />;

    return <IconMenu size={12} htmlColor={`#fff`} />;
  };
  return (
    <>
      <Drawer
        variant='permanent'
        open={isExpanded}
        className={`${isExpanded ? 'drawer-open' : ''}`}
      >
        <Toolbar />
        <DrawerHeader>
          <ProjectSelector isExpanded={isExpanded} />
        </DrawerHeader>

        <DrawerBody>
          <Box>
            <div id='animate'>
              <nav className='nav'>
                <NavList list={menus} navOpen={isExpanded} />
              </nav>
            </div>
          </Box>
        </DrawerBody>

        <IconButton
          circled
          className={'nav-toggle-button'}
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
          edge='start'
          onMouseEnter={() => setIsIconShown(true)}
          onMouseLeave={() => setIsIconShown(false)}
        >
          {getNavToggleIcon()}
        </IconButton>
      </Drawer>
    </>
  );
};
export default Navigator;
