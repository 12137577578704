import {useInfiniteQuery} from 'react-query';
import {ApiFactory} from '@/utils/apiFactory';
import {exceptionProcessorForQuery} from '@/services/infrastructure/exceptionProcessorForQuery';

export const useInfiniteQueryToListMyProjects = ({spaceId}, size = 50, options) => useInfiniteQuery(
  [`/spaces/${spaceId}/projects`, size],
  async (context) => {
    try {
      const response = await ApiFactory.cpdV3({
        method: 'get',
        url: '/users/me/projects',
        headers: {
          'cloudplex-space-id': spaceId
        },
        params: {
          size: size,
          cursor: context.pageParam
        },
        signal: context.signal
      });
      return response.data;
    } catch (error) {
      exceptionProcessorForQuery(error);
      throw error;
    }
  },
  {
    ...options,
    getNextPageParam: ({nextCursor}) => nextCursor ?? null
  }
);
