export const forbidden = (error) => {
  if (error?.response?.status === 403) {
    return [
      {
        type: 'error',
        translation: {
          key: "label.don't have permission Please contact your administrator"
        }
      }
    ];
  }
};

export const uncaught = (error) => {
  if (error?.message != null) {
    return [
      {
        type: 'error',
        translation: {
          key: error?.message
        }
      }
    ];
  }
};
