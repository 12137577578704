
export const PUBLIC_URL = process.env.PUBLIC_URL;

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORT_LOCALES = [
  {
    name: 'English',
    value: 'en-us',
    label: 'English'
  },
  {
    name: '한국어',
    value: 'ko-kr',
    label: 'Korean'
  }
];

export const SESSION_STORED_KEY = 'cloudplex.delivery.session';
export const OAUTH_TOKEN_STORED_KEY = 'token';
export const CDN_COLOR = {
  TOTAL: ['#000000', '#000000'],
  AWS_CLOUDFRONT: ['#fd931e', '#eb6400'],
  AKAMAI: ['#44abff', '#1381d1'],
  LIMELIGHT: ['#7fc224', '#4e800b'],
  AZURE_CDN: ['#1d73e5', '#0e41a0'],
  KT_SOLBOX: ['#dd090a', '#ff296b'],
  ALIBABA_CLOUD_CDN: ['#fc5d02', '#b54200'],
  TENCENT_CLOUD_CDN: ['#0f76bf', '#005695'],
  TENCENT_CLOUD_EDGEONE: ['#0f76bf', '#005695'],
  LG_UPLUS: ['#ea18c1', '#eb416d'],
  GOOGLE_CLOUD_CDN: ['#feba02', '#e08a00'],
  CLOUDFLARE_CDN: ['#d96400', '#88320c'],
  HYOSUNG_ITX_CDN: ['#133B81', '#133B81'],
  BYTEPLUS_CDN: ['#0766FC', '#0656d4'],
  LG_NIMBUS: ['#EC0B8D', '#c20b73'],
  CUSTOM: ['#9fa1aa', '#74767c'],
  ETC_CDN: ['#9fa1aa', '#74767c']
};

export const STORAGE_LABEL_MAPPER = {
  AWS_S3: 'Amazon S3'
};
export const DNS_LABEL_MAPPER = {
  AWS_ROUTE53: 'Amazon Route 53'
};
export const CDN_ICON_MAPPER = {
  AWS_CLOUDFRONT: 'cloudfront',
  AKAMAI: 'akamai',
  LIMELIGHT: 'limelight',
  AZURE_CDN: 'azure',
  KT_SOLBOX: 'kt',
  ALIBABA_CLOUD_CDN: 'alibaba',
  TENCENT_CLOUD_CDN: 'tencent',
  TENCENT_CLOUD_EDGEONE: 'tencent',
  LG_UPLUS: 'lguplus',
  GOOGLE_CLOUD_CDN: 'google',
  CLOUDFLARE_CDN: 'cloudflare',
  HYOSUNG_ITX_CDN: 'hyosung',
  BYTEPLUS_CDN: 'byteplus',
  LG_NIMBUS: 'lgNimbus',
  CUSTOM: 'custom'
};

export const CDN_SORTING_ORDER = [
  'AWS_CLOUDFRONT',
  'AKAMAI',
  'AZURE_CDN',
  'LIMELIGHT',
  'ALIBABA_CLOUD_CDN',
  'TENCENT_CLOUD_CDN',
  'TENCENT_CLOUD_EDGEONE',
  'GOOGLE_CLOUD_CDN',
  'CLOUDFLARE_CDN',
  'KT_SOLBOX',
  'LG_UPLUS',
  'HYOSUNG_ITX_CDN',
  'BYTEPLUS_CDN',
  'LG_NIMBUS',
  'CUSTOM'
];
export const CDN_PROVIDERS = [
  {
    label: 'All CDN Provider',
    value: 'All CDN Provider',
    color: ''
  },
  {
    label: 'Amazon CloudFront',
    value: 'cloudfront',
    color: '#ff931e'
  },
  {
    label: 'Akamai CDN',
    value: 'akamai',
    color: '#44abff'
  },
  {
    label: 'Azure CDN',
    value: 'azure',
    color: '#1E73E5'
  },
  {
    label: 'Edgio CDN',
    value: 'limelight',
    color: '#7FC225'
  },
  {
    label: 'Alibaba Cloud CDN',
    value: 'alibaba',
    color: '#FD5D02'
  },
  {
    label: 'Tencent Cloud CDN',
    value: 'tencent',
    color: '#0076bf'
  },
  {
    label: 'KT CDN',
    value: 'kt',
    color: '#dd060a'
  },
  { label: 'LG U+ CDN', value: 'lguplus', color: '#a50034' },
  {
    label: 'Google Cloud CDN',
    value: 'google',
    color: '#FEBA02'
  },
  {
    label: 'Cloudflare CDN',
    value: 'cloudflare',
    color: '#d96400'
  },
  {
    label: 'Hyosung ITX CDN',
    value: 'hyosung',
    color: '#133B81'
  },
  {
    label: 'BytePlus CDN',
    value: 'byteplus',
    color: '#0766FC'
  },
  { label: 'LG Nimbus CDN', value: 'lgNimbus', color: '#EC0B8D' },
  {
    label: 'Custom',
    value: 'custom',
    color: '#9fa1aa'
  }
];

export const LIMIT_BYTES = {
  name: 255,
  title: 255,
  description: 2000,
  attribution: 200,
  tag: 150,
  comment: 255,
  descriptionChannel: 2000,
  roleTitle: 50,
  roleDescription: 512,
  identifier: 30,
  dnsActualDomain: 128,
  dnsHostedZoneName: 128
};

export const CDN_METRICS_INTERVAL = {
  minute5: 'FIVE_MINUTE',
  hour1: 'ONE_HOUR',
  day1: 'ONE_DAY'
};
