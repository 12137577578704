import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import NavList from '@/components/navigation/NavList';
import {useSelector} from "react-redux";
import {Toolbar, Typography} from "@mui/material";
import {BackToConsoleButton, Drawer, DrawerBody, DrawerFooter, NavTitle} from "./styles/nav.styled";
import {IconBack, IconProjects} from "@mzc-cloudplex/icons";
import {Box} from "@mui/system";
import theme from "@/styles/theme";
import menuService from "@/services/menuService";
import {useDeliveryLocation} from "@/hooks/useDeliveryLocation";

const NavigatorAdmin = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const projectState = useSelector((state) => state.project); // check
  const location = useLocation();

  const {spaceId} = useDeliveryLocation();

  const adminMenus = [
    {
      id: 'Manage',
      name: `Manage`,
      active: false,
      path: '',
      isSelect: () => false,
      isVisible: () => true,
      subMenus: [
        {
          id: 'Projects',
          name: `Projects`,
          path: menuService.paths.admin.projects(spaceId),
          iconClass: 'sprite sprite-menu-project',
          icon: <IconProjects size={16}/>,
          disabled: false,
          active: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.admin.projects(spaceId)),
          isVisible: () => true,
        }
      ]
    }
  ];

  const handleGoToUserClick = () => {
    if (projectState.id !== '') {
      navigate(menuService.paths.projectHome(spaceId, projectState.id));
    } else {
      navigate(menuService.paths.spaces());
    }
  };

  return (
    <>
      {/*<Drawer variant="permanent" open={open} className={`${open ? 'admin drawer-open' : 'admin'}`}>*/}
      <Drawer variant="permanent" open={true} className={'admin drawer-open'}>
        <Toolbar/>
        <DrawerBody>
          <Box
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto'
            }}>
            <div id="animate">
              <div className="scroll" id='scroll'>
                <NavTitle>{t('label.Administration')}</NavTitle>
                <NavList list={adminMenus} navOpen={true}/>
              </div>
            </div>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <BackToConsoleButton onClick={handleGoToUserClick}>
            <Box width={22} height={22} borderRadius={5}>
              <IconBack size={12} htmlColor={`#fff`}/>
            </Box>
            <div>
              <Typography variant={'subtitle1'} component={'strong'} color={theme.palette.text.primary}>
                {t('label.Leave')}
              </Typography>
            </div>
          </BackToConsoleButton>
        </DrawerFooter>
      </Drawer>
    </>
  );
};
export default NavigatorAdmin;
