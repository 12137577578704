const production = {
  domain: 'cloudplex.so',
  startApplicationUrl: 'https://start.cloudplex.so',
  cpd: {
    apiV3: 'https://xjhg0qmqkg.execute-api.ap-northeast-2.amazonaws.com/v3',
    channelV1: 'https://channel.cloudplex.me/v1/receive'
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: 'oAAEablZVqMXpX2PXQzaF7mIXhEL2c',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default production;
