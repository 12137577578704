import development from '@/config/development';
import local from '@/config/local';
import production from '@/config/production';
import stage from '@/config/stage';
import qa from '@/config/qa';
import { default as lodash } from '@/utils/lodash';

const getConfig = () => {
  const env = process.env.REACT_APP_NOT_SECRET_CODE;
  const cond = lodash.cond([
    [lodash.equals('production'), lodash.set('env', lodash, production)],
    [lodash.equals('stage'), lodash.set('env', lodash, stage)],
    [lodash.equals('development'), lodash.set('env', lodash, development)],
    [lodash.equals('local'), lodash.set('env', lodash, local)],
    [lodash.equals('qa'), lodash.set('env', lodash, qa)],
    [lodash.T, lodash.identity]
  ]);

  const getEnv = (env) => (lodash.isEmpty(env) ? env : lodash.pipe(lodash.trim, cond)(env));

  const result = getEnv(env);

  return result;
};

export default getConfig();
