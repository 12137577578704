import {useQuery} from 'react-query';
import {ApiFactory} from '@/utils/apiFactory';
import {exceptionProcessorForQuery} from '@/services/infrastructure/exceptionProcessorForQuery';

export const createKeyForGetMyInfo = () => [`/my-info`];
export const useQueryToGetMyInfo = (
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) => useQuery(
  createKeyForGetMyInfo(),
  async (context) => {
    try {
      const response = await ApiFactory.cpdV3({
        method: 'get',
        url: '/users/me',
        signal: context.signal
      });
      return response.data;
    } catch (error) {
      exceptionHandler.handle(error);
      throw error;
    }
  },
  {
    staleTime: 1000 * 5, // 5초 캐쉬 사용
    cacheTime: 1000 * 5,
    ...options
  }
);
