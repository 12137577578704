import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Email,
  Name,
  ProfileItem,
  ProfileMenuItem
} from '@/components/layouts/header/profileMenu.styled';
import { IconButton } from '@mzc-cloudplex/core';
import Popover from '@mui/material/Popover';
import { Divider, Grid, List, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import {
  OAUTH_TOKEN_STORED_KEY,
  SESSION_STORED_KEY,
} from '@/constants';
import { useNavigate } from 'react-router-dom';
import theme from '@/styles/theme';
import { IconOpenInNew } from '@mzc-cloudplex/icons';
import menuService from "@/services/menuService";
import config from "@/config";

const ProfileMenu = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickLogout = (e) => {
    // const { uploadQueue } = state ? state : '';
    const logout = () => {
      Cookies.remove(SESSION_STORED_KEY);
      Cookies.remove(OAUTH_TOKEN_STORED_KEY, { domain: config.domain});
      localStorage.removeItem(SESSION_STORED_KEY);
      localStorage.removeItem(OAUTH_TOKEN_STORED_KEY);
      navigate(menuService.paths.welcome());
    };

    // const getProjectNames = _.pipe(_.get('targetObjects'), _.keys, _.tap());
    // const getPrefixes = _.curry((state, projectName) =>
    //   _.pipe(_.get(`targetObjects[${projectName}]`), _.keys)(state)
    // );
    // const getPathes = _.curry((state, projectName, prefix) =>
    //   _.pipe(_.get(`targetObjects[${projectName}][${prefix}]`), _.keys)(state)
    // );
    // const getUploadStatus = _.curry((state, projectName, prefix, path) =>
    //   _.pipe(
    //     _.get(`targetObjects[${projectName}][${prefix}]`),
    //     (v) => v[path],
    //     _.get('status')
    //   )(state)
    // );
    // const anyUploading = _.some((projectName) => {
    //   const getUploadStatuses = _.pipe(
    //     _.map((prefix) => {
    //       const composer = _.pipe(
    //         getPathes(uploadQueue, projectName),
    //         _.map(getUploadStatus(uploadQueue, projectName, prefix))
    //       );
    //       return composer(prefix);
    //     }),
    //     _.flatten
    //   );
    //
    //   const composer = _.pipe(
    //     getPrefixes(uploadQueue),
    //     getUploadStatuses,
    //     _.some(_.equals('uploading'))
    //   );
    //   return composer(projectName);
    // });

    // const composer = _.pipe(getProjectNames, anyUploading, (isUploadingExist) => {
    //   if (isUploadingExist) {
    //     if (window.confirm(_core.getScreenOutMessage(e))) {
    //       logout();
    //     }
    //   } else {
    //     logout();
    //   }
    // });
    //
    // composer(uploadQueue);
    logout();
  };

  const onHandleClickProfile = useCallback(() => {
    //window.open(`${process.env.REACT_APP_MEGAZONE_MYACCOUNT}`);
    window.open(`https://myaccount.megazone.com/info`);
  }, []);

  return (
    <>
      <IconButton size={'large'} circled onClick={handleClick}>
        <Avatar
          alt={user?.lastName}
          src={user?.profileImageUrl}
          sx={{ width: 26, height: 26, fontSize: 16 }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.MuiPaper-root ': {
            width: `280px`
          }
        }}
      >
        <List>
          <ProfileItem>
            <Typography variant={'caption'} color={theme.palette.secondary[600]}>
              <strong>Account</strong>
            </Typography>
            <Grid container alignItems={`center`} sx={{ marginTop: `12px` }}>
              <Grid item sx={{ marginRight: `12px` }}>
                <Avatar
                  alt={user?.lastName}
                  src={user?.profileImageUrl}
                  sx={{ width: 44, height: 44 }}
                />
              </Grid>
              <Grid item>
                <Name>{user?.lastName + user?.firstName}</Name>
                <Email>{user?.username}</Email>
              </Grid>
            </Grid>
          </ProfileItem>
          <Divider />
          <ProfileMenuItem onClick={onHandleClickProfile}>
            <Typography fontWeight={500} component={'strong'}>
              {t(`label.Manage Account`)}
            </Typography>
            <IconOpenInNew size={16} />
          </ProfileMenuItem>
          <Divider />
          <ProfileMenuItem onClick={onClickLogout}>
            <Typography fontWeight={500} component={'strong'}>
              {t(`label.Logout`)}
            </Typography>
          </ProfileMenuItem>
        </List>
      </Popover>
    </>
  );
};

export default ProfileMenu;
