import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '@/utils/utils';
import theme from '@/styles/theme';
import { List } from './styles/nav.styled';
import {
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@mzc-cloudplex/icons';
import config from '@/config';
import { alpha } from '@mui/system';
import { Tooltip } from '@mzc-cloudplex/core';

const NavItem = ({ item, navOpen }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const isOneDepthStaticMenu = item.path === '';
  return (
    <li>
      {item.divider && <Divider sx={{ margin: `16px 0` }} />}
      {isOneDepthStaticMenu && !item.iconClass ? (
        <ListSubheader component={'div'} className={`${!navOpen ? 'minimize' : ''}`}>
          {t(`label.${item.name}`)}
        </ListSubheader>
      ) : isOneDepthStaticMenu && item.iconClass ? (
        <Tooltip title={`${navOpen ? '' : t(`label.${item.name}`)}`} placement={'right'}>
          <ListItemButton component={'button'} onClick={handleClick} selected={item.isSelect()}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{t(`label.${item.name}`)}</ListItemText>
            {!isEmpty(item?.subMenus) && (
              <React.Fragment>
                {open ? (
                  <IconChevronUp size={10} htmlColor={alpha(theme.palette.primary.main, 0.5)} />
                ) : (
                  <IconChevronDown size={10} htmlColor={alpha(theme.palette.secondary.main, 0.5)} />
                )}
              </React.Fragment>
            )}
          </ListItemButton>
        </Tooltip>
      ) : (
        <Tooltip title={`${navOpen ? '' : t(`label.${item.name}`)}`} placement={'right'}>
          <ListItemButton
            component={NavLink}
            to={item.path}
            selected={item.isSelect()}
            // style={{pointerEvents: isMenuActive ? 'none' : ''}}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{t(`label.${item.name}`)}</ListItemText>
          </ListItemButton>
        </Tooltip>
      )}
      {!isEmpty(item?.subMenus) && (
        <Collapse in={open}>
          <List>
            {item.subMenus
              .filter((item) => item.isVisible())
              .map((subItem, subIndex) => (
                <li key={subIndex}>
                  <Tooltip
                    title={`${navOpen ? '' : t(`label.${subItem.name}`)}`}
                    placement={'right'}
                  >
                    <ListItemButton
                      component={NavLink}
                      to={subItem.path}
                      selected={subItem.isSelect()}
                      // style={{ pointerEvents: isSubMenuActive ? 'none' : '' }}
                    >
                      {subItem.icon && <ListItemIcon>{subItem.icon}</ListItemIcon>}
                      <ListItemText>{t(`label.${subItem.name}`)}</ListItemText>
                    </ListItemButton>
                  </Tooltip>
                </li>
              ))}
          </List>
        </Collapse>
      )}
    </li>
  );
};

const NavList = ({ list, navOpen }) => (
  <List navOpen={navOpen}>
    {list
      .filter(
        (item) =>
          (item.supportedEnvironments == null
          || item.supportedEnvironments.includes(config.env))
          && item.isVisible()
      )
      .map((item, index) => (
        <NavItem key={index} item={item} navOpen={navOpen} />
      ))}
  </List>
);

NavList.propTypes = {};

export default NavList;
