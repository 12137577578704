import React, {Suspense, useCallback, useEffect} from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {detect} from 'detect-browser';
import {ModalProvider} from 'react-modal-hook';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error404 from '@/routes/errors/Error404';
import routes from '@/routes';
import {CloudPlexThemeProvider} from '@mzc-cloudplex/core';
import theme from '@/styles/theme';
import {Navigator} from 'src/components/navigation';
import {QueryClient, QueryClientProvider} from "react-query";
import GlobalCssOverride from '@/styles/global';
import CssBaseline from '@mui/material/CssBaseline';
import menuService from "@/services/menuService";
import {isNotSupportBrowser} from "@/utils/isNotSupportBrowser";
import LayoutForPreview from "@/components/layouts/LayoutForPreview";
import LayoutForWelcome from "@/components/layouts/LayoutForWelcome";
import LayoutForError from "@/components/layouts/LayoutForError";
import LayoutForAdmin from "@/components/layouts/LayoutForAdmin";
import LayoutForNothing from "@/components/layouts/LayoutForNothing";
import LayoutForSpaces from "@/components/layouts/LayoutForSpaces";
import Layout from "@/components/layouts/Layout";
import CircleSpinner from "@/components/common/spinners/CircleSpinner";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const browser = detect();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        useErrorBoundary: false,
        refetchOnWindowFocus: false
      },
      mutations: {
        retry: 0,
        useErrorBoundary: false
      }
    }
  });

  useEffect(() => {
    isNotSupportBrowser(browser) && navigate(menuService.paths.browserNotSupported());
  }, [navigate]);

  const SelectedLayout = useCallback(
    (location, window) => {
      if (window.opener && window.name && window.name.indexOf('popup') > -1) {
        return LayoutForPreview;
      } else if (location.pathname.indexOf('/welcome') !== -1) {
        return LayoutForWelcome;
      } else if (location.pathname.indexOf('/error') !== -1) {
        return LayoutForError;
      } else if (location.pathname.indexOf('/admin') !== -1) {
        return LayoutForAdmin;
      } else if (location.pathname.indexOf('/megazone/login') !== -1) {
        return LayoutForNothing;
      } else if (location.pathname === '/' || location.pathname === '/spaces' || location.pathname === '/spaces/') {
        return LayoutForSpaces;
      } else {
        return Layout;
      }
    },
    [location, window]
  )(location, window);

  return (
    <QueryClientProvider client={queryClient}>
      <CloudPlexThemeProvider theme={theme}>
        <ModalProvider>
          <CssBaseline/>
          <GlobalCssOverride/>
          <Suspense fallback={<CircleSpinner/>}>
          <SelectedLayout Navigator={Navigator}>
            <Suspense fallback={<CircleSpinner/>}>
              <Routes>
                {routes.map((route, idx) => (
                  <Route key={`route-${idx}`} {...route} />
                ))}
                <Route path={'*'} element={Error404}/>
              </Routes>
            </Suspense>
          </SelectedLayout>
          </Suspense>
          <ToastContainer
            autoClose={3000}
            position={toast.POSITION.TOP_RIGHT}
            hideProgressBar={false}
            pauseOnHover={true}
            pauseOnFocusLoss={true}
            rtl={false}
            newestOnTop={true}
            draggable={false}
            useLazyContainer={true}
            theme={'colored'}
          />
        </ModalProvider>
      </CloudPlexThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
