import React, {lazy} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Toolbar} from "@mui/material";
import {Container, Content, Documents} from "@/components/layouts/layout.styled";
import styleTheme from '@/styles/theme';

const Footer = lazy(() => import('@/components/layouts/footer/Footer'));
const Header = lazy(() => import('@/components/layouts/header/Header'));
const Loader = lazy(() => import('@/components/common/spinners/LineSpinner'));
const NavigatorAdmin = lazy(() => import('@/components/navigation/NavigatorAdmin'));

const LayoutForAdmin = ({children}) => (
  <DndProvider backend={HTML5Backend}>
    <Documents theme={styleTheme}>
      <Header/>
      <NavigatorAdmin/>
      <Container>
        <Toolbar/>
        <Content>
          {children}
        </Content>
        <Footer/>
      </Container>
      <Loader r-if={_.get('loading', global)}/>
    </Documents>
  </DndProvider>
);

export default LayoutForAdmin;
