import {toastTypesDic, useToast} from '@/hooks';
import {CanceledError} from 'axios';
import {uncaught} from '@/services/infrastructure/exceptionAnalyzers/common';
import {
  badRequestForDelivery,
  conflictForDelivery,
  notFoundForDelivery,
  serviceUnavailableForDelivery
} from '@/services/infrastructure/exceptionAnalyzers/delivery';
import {useTranslation} from 'react-i18next';

export const exceptionProcessorForQuery = () => {
  const {toast} = useToast();
  const {t} = useTranslation();

  return {
    handle: (error) => {
      if (error instanceof CanceledError || error?.response?.status === 403) {
        return;
      }
      const processors = [
        badRequestForDelivery,
        notFoundForDelivery,
        conflictForDelivery,
        serviceUnavailableForDelivery,
        (error) => {
          if (error?.response?.status === 401
            || error?.response?.status === 403) {
            // ignore
            return;
          }
          return uncaught(error);
        }
      ];
      for (let processor of processors) {
        const messages = processor(error);
        if (messages?.length > 0) {
          messages.forEach((message) => {
            toast(t(message.translation.key, {property: t(message.translation.property)}), {
              type: toastTypesDic.error,
              onClose: () => {
              }
            });
          });
          return;
        }
      }
    }
  };
};
