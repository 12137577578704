import { createTheme } from '@mui/material/styles';
import {alpha} from "@mui/system";

const { palette } = createTheme();
const { augmentColor } = palette;

export const myTheme = {
  breakpoints: {
    values: {
      xs: 361,
      sm: 601,
      md: 961,
      lg: 1281,
      xl: 1921
    }
  },
  palette: {
    type: 'light',
    background: {
      default: '#f2f2f2',
      paper: '#fff'
    },
    common: {
      black: '#000000',
      white: '#ffffff'
    },
    default: {
      900: '#000000',
      800: '#303637',
      700: '#53575d',
      600: '#666a71',
      500: '#91959a',
      400: '#b1b5ba',
      300: '#d5d9de',
      200: '#e4e9ee',
      100: '#f1f4f6',
      50: '#f6f8f9',
      main: '#c3cbd4',
      contrastText: '#ffffff'
    },
    primary: {
      A700: '#2E2E31',
      A400: '#87878F',
      A200: '#BEC0C9',
      A100: '#D1D2D9',
      900: '#0011c0',
      800: '#0022d9',
      700: '#002fe3',
      600: '#003bf0',
      500: '#0144fc',
      400: '#2763EA',
      300: '#6280ff',
      200: '#96a4fe',
      100: '#c2c8fe',
      50: '#e7e9ff',
      main: '#2763EA',
      light: '#6280ff',
      dark: '#0011c0',
      contrastText: '#ffffff'
    },
    secondary: {
      A700: '#33525E',
      A400: '#668896',
      A200: '#A2B9C3',
      A100: '#C9D4F4',
      900: '#2B2B31',
      800: '#3c3d4f',
      700: '#5b5b6f',
      600: '#6f6f83',
      500: '#83839A',
      400: '#9898ad',
      300: '#B6B6CA',
      200: '#D0D0DF',
      100: '#DFDFEE',
      50: '#EFEFF6',
      main: '#3c3d4f',
      light: '#B6B6CA',
      dark: '#2B2B31',
      contrastText: '#ffffff'
    },
    info: {
      A700: '#00bfa5',
      A400: '#1DE9B6',
      A200: '#64ffda',
      A100: '#a7ffeb',
      900: '#005739',
      800: '#007453',
      700: '#008461',
      600: '#009571',
      500: '#00a27d',
      400: '#29b190',
      300: '#51c0a2',
      200: '#83d2bc',
      100: '#b4e3d6',
      50: '#d0f4ef',
      main: '#29b190',
      light: '#51c0a2',
      dark: '#005739',
      contrastText: '#ffffff'
    },
    success: {
      900: '#005800',
      800: '#0f7600',
      700: '#218701',
      600: '#2e9812',
      500: '#39a71c',
      400: '#59b445',
      300: '#78c167',
      200: '#9fd193',
      100: '#c5e3bd',
      50: '#e7f4e4',
      main: '#2e9812',
      light: '#39a71c',
      dark: '#005800',
      contrastText: '#ffffff'
    },
    warning: {
      900: '#e65400',
      800: '#ef6f00',
      700: '#f57f00',
      600: '#fb8f01',
      500: '#ff9b02',
      400: '#ffa927',
      300: '#ffb94e',
      200: '#ffcd81',
      100: '#ffe1b3',
      50: '#fff3e0',
      main: '#f57f00',
      light: '#ff9b02',
      dark: '#e65400',
      contrastText: '#ffffff'
    },
    error: {
      900: '#c82b06',
      800: '#e13a11',
      700: '#ef4116',
      600: '#fd491b',
      500: '#ff4f20',
      400: '#ffa927',
      300: '#ff8764',
      200: '#ffaa90',
      100: '#ffcbbc',
      50: '#fde9e7',
      main: '#ef4116',
      light: '#ff4f20',
      dark: '#c82b06',
      contrastText: '#ffffff'
    },
    grayscale: {
      900: '#171717',
      800: '#373737',
      700: '#555555',
      600: '#686868',
      500: '#999999',
      400: '#b0b0b0',
      300: '#d5d5d5',
      200: '#f2f2f2',
      100: '#F8F8F8',
      50: '#Fcfcfc',
      main: '#686868',
      light: '#f2f2f2',
      dark: '#171717',
      contrastText: '#ffffff'
    },
    grayscaleSecondary: augmentColor({
      color: {
        A700: "#565960",
        A400: "#9DA2AA",
        A200: "#D9DDE3",
        A100: "#c9d4f4",
        900: '#04060F',
        800: '#11193f',
        700: '#1B2856',
        600: '#2A396D',
        500: '#304075',
        400: '#4D5A87',
        300: '#6B769A',
        200: '#939BB7',
        100: '#BDC2D4',
        50: '#E5E7ED',
        main: '#11193f',
        light: '#939BB7',
        dark: '#04060F',
        contrastText: '#ffffff'
      }
    }),
    text: {
      primary: '#3c3d4f',
      secondary: '#6f6f83',
      disabled: '#999999'
    },
    divider: '#dfdfee'
  },
  mixins: {
    toolbar: {
      minHeight: 60,
      '@media (min-width: 600px)': {
        minHeight: 60
      }
    }
  },
  typography: {
    fontFamily:
      '"Noto Sans KR",-apple-system,BlinkMacSystemFont,"Malgun Gothic", "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";',
    htmlFontSize: 16,
    fontSize: 14,
    fontWeight: `400`,
    h1: {
      fontWeight: 'bold',
      fontSize: 28
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 24
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 20
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 16
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 15
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: 14
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: 14
    },
    body1: {
      fontWeight: 'normal',
      fontSize: 13
    },
    body2: {
      fontWeight: 'normal',
      fontSize: 12
    },
    caption: {
      fontSize: `11px`
    }
  },
  spacing: 8,
};
export const overrideTheme = {
  ...myTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html {
        overflow: hidden !important;
      }
      html,body,#root {
        height: 100%;
      }
      .container {
          height: 100%;
      }
      body, button, input, select, textarea {
          font-family: 'Noto Sans KR', sans-serif;
          color: #3c3d4f ;
      }
      body {
        font-size: 14px;
          color: #3c3d4f !important;
      }
      a {
        color: inherit;
      }
      a:focus {
        outline: 0;
      }
      a:hover,
      a:active {
        outline: 0;
        text-decoration: underline;
      }
       button {
           margin: 0;
           padding: 0;
           border: none;
           background-color: transparent;
       }
       address {
        font-style: normal;
       }
      div ,
      ul {
          &::-webkit-scrollbar {
            width: 14px;
            height: 14px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #dce1e5;
            border-radius: 8px;
            background-clip: padding-box;
            border: 4px solid transparent;
          }
      },
      *::before, *::after {
        box-sizing: initial;
      },
      input::-webkit-search-decoration,
      input::-webkit-search-cancel-button,
      input::-webkit-search-results-button,
      input::-webkit-search-results-decoration{
          display:none;
      }
    `
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: `5px 10px`,
          fontWeight: `normal`,
          fontSize: `13px`,
          marginTop: `4px !important`
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiSelect-select': {
            fontSize: `14px`,
            borderRadius: `2px`,
            padding: `4.9px 14px 9.98px 14px`
          },
          '.MuiSelect-icon': {
            top: `calc(50% - .6em)`
          },
          ':hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `#2763ea`
            }
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: `1px`
            }
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ownerState}) => ({
          alignItems: `center`,
          ...((!ownerState.variant ||  ownerState.variant === 'standard' ) && {
            ...(ownerState.color && {
              color: myTheme.palette.text.primary,
              border: `1px solid ${myTheme.palette[ownerState.color][200]}`,
              backgroundColor: alpha(myTheme.palette[ownerState.color][50], 0.5),
              '.MuiAlert-message': {
                fontSize: `0.875rem`,
              }
            })
          }),
        })
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '.MuiInputBase-root': {
            gap: myTheme.spacing,
            '.MuiAutocomplete-input': {
              padding: `0.95px 4px 0.95px 5px`,
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            top: 0,
          },
        }),
        endAdornment: {
          top: `calc(50% - 1rem)`,
        },
        inputRoot: {
          padding: `9px`,
          fontSize: `14px`,
        },
        tag: {
          margin: 0,
          '&:not(div)': {
            color: myTheme.palette.primary.contrastText,
            backgroundColor: myTheme.palette.primary.main,
            paddingLeft: myTheme.spacing,
            paddingRight: myTheme.spacing,
            borderRadius: myTheme.spacing * 2,
          },
        },
        popper: {
          '.MuiAutocomplete-listbox': {
            '.MuiAutocomplete-option': {
              minHeight: `40px`,
              '&:hover, &.Mui-focused': {
                backgroundColor: alpha(myTheme.palette.secondary.main, 0.08),
              },
              '&.Mui-selected': {
                backgroundColor: alpha(myTheme.palette.grayscaleSecondary.A100, 0.5),
              },
            },
          },
        },
      },
      variants: [
        {
          props: {
            size: 'small',
          },
          style: {
            '.MuiInputBase-root': {
              '&.MuiInputBase-sizeSmall': {
                paddingTop: `4px`,
                paddingBottom: `4px`,
              },
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: `1rem`,
          borderRadius: `4px`,
          backgroundColor: myTheme.palette.common.white,
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: myTheme.palette.primary.main
            }
          },
          '&.Mui-disabled': {
            ".MuiOutlinedInput-notchedOutline": {
              backgroundColor: alpha(myTheme.palette.grayscaleSecondary[50], 0.2)
            },
            "&:hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: myTheme.palette.secondary[300]
              }
            },
          },
          input: {
            padding: `0.71rem 0.875rem`,
            "&[type='search']": {
              "&::-webkit-search-cancel-button": {
                appearance: `none`,
                height: `20px`,
                width: `20px`,
                background: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' %3E%3Cpath d='M6 12a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6zm0-4.943 2.12 2.12 1.06-1.06L7.063 6l2.121-2.124-1.06-1.06L6 4.936 3.881 2.815l-1.06 1.06L4.942 6 2.821 8.118l1.06 1.06L6 7.058z' fill='%23939bb7' /%3E%3C/svg%3E") no-repeat 50% 50%`,
                backgroundSize: `12px 12px`,
                cursor: `pointer`
              }
            },
          },
          "&:has(input[type='search'])": {
            input: {
              paddingLeft: 0
            },
            ".MuiInputAdornment-positionStart": {
              width: 16,
              transition: `width 0.2s linear`
            }
          },
          "&:active, &:focus, &.Mui-focused": {
            "&:has(input[type='search'])": {
              ".MuiInputAdornment-positionStart": {
                width: 0,
                height: 0,
                overflow: `hidden`
              }
            }
          }
        },
        notchedOutline: {
          top: 0,
          borderWidth: `1px !important`,
          borderColor: myTheme.palette.secondary[300]
        },
        multiline: {
          height: `auto`,
          minHeight: 88,
          padding: myTheme.spacing * 2,
          alignItems: `flex-start`,
          borderRadius: 2
        }
      },
      variants: [
        {
          props: {
            size: 'small',
          },
          style: {
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      ],
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: `1rem`,
          borderRadius: `4px`,
          "&:has(input[type='search'])": {
            paddingLeft: `0.75rem`,
            paddingRight: `0.5rem`,
            border: `1px solid transparent`,
            "&:hover, &:focus, &.Mui-focused": {
              backgroundColor: myTheme.palette.common.white,
              border: `1px solid ${myTheme.palette.primary.main}`,
            }
          },
          "&:before, &:after": {
            content: `none`
          },
          input: {
            padding: `0.71rem 0.875rem`,
            "&[type='search']": {
              paddingLeft: 0,
              paddingRight: 0,
              "&::-webkit-search-cancel-button": {
                appearance: `none`,
                height: `20px`,
                width: `20px`,
                background: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' %3E%3Cpath d='M6 12a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6zm0-4.943 2.12 2.12 1.06-1.06L7.063 6l2.121-2.124-1.06-1.06L6 4.936 3.881 2.815l-1.06 1.06L4.942 6 2.821 8.118l1.06 1.06L6 7.058z' fill='%23939bb7' /%3E%3C/svg%3E") no-repeat 50% 50%`,
                backgroundSize: `12px 12px`,
                cursor: `pointer`
              }
            }
          },
          ".MuiInputAdornment-positionStart": {
            marginTop: `0 !important`
          },
        },
        sizeSmall: {
          input: {
            padding: `0.3711rem 0.5rem`
          }
        },
        inputAdornedStart: {
          marginTop: `0 !important`
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginLeft: 0,
          fontSize: `0.875rem`,
        }
      }
    }
  }
};

const theme = createTheme({ ...overrideTheme });

export default theme;
