import {
  Avatar as MuiAvatar,
  Drawer as MuiDrawer,
  List as MuiList,
  styled,
  Button as MuiButton
} from '@mui/material';

import ButtonBase from '@mui/material/ButtonBase';
import { alpha } from '@mui/system';
import { Link } from 'react-router-dom';

const drawerWidth = 254;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  padding: `12px`,
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: `60px`,
  padding: `12px 6px`,
  overflowX: 'hidden'
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  paddingBottom: `16px`
}));

export const DrawerBody = styled('div')(({ theme }) => ({
  flex: 1,
  position: 'relative',
  overflow: `hidden auto`
}));
export const DrawerFooter = styled('div')(({ theme }) => ({
  '&:has(.admin)': {
    button: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: `${theme.palette.primary[200]}0D`
      },
      '&:active': {
        backgroundColor: `${theme.palette.primary[200]}1A`
      }
    }
  }
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root ': {
    boxSizing: 'border-box',
    backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.5),
    // backgroundColor: `#e5e7ed`,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.8)
    }
  },
  '.MuiDivider-root': {
    borderColor: theme.palette.grey[200],
    backgroundColor: `transparent`
  },
  '&.admin': {
    // '.MuiPaper-root': {
    //   backgroundColor: theme.palette.secondary[800],
    //   color: theme.palette.secondary[100]
    // },
    // '.MuiList-root': {
    //   margin: `0 10px`,
    //   '& .MuiList-root': {
    //     margin: `0`,
    //     paddingTop: `10px`,
    //     paddingBottom: `10px`
    //   }
    // },
    // '.MuiListSubheader-root': {
    //   position: `static`,
    //   lineHeight: `1`,
    //   padding: `0 9px`,
    //   fontSize: `11px`,
    //   color: theme.palette.secondary[200],
    //   fontWeight: `bold`,
    //   backgroundColor: `transparent`
    // },
    // '.MuiListItemButton-root': {
    //   height: `40px`,
    //   borderRadius: `5px`,
    //   '.MuiListItemIcon-root': {
    //     color: theme.palette.primary.contrastText,
    //     opacity: `0.3`
    //   },
    //   '&:hover': {
    //     backgroundColor: `${theme.palette.primary[200]}0D`
    //   },
    //   '&.Mui-selected': {
    //     color: theme.palette.primary.contrastText,
    //     backgroundColor: `${theme.palette.primary[200]}1A`,
    //     '.MuiListItemIcon-root': {
    //       opacity: `1`
    //     },
    //     '&:hover': {
    //       backgroundColor: `${theme.palette.primary[200]}26`
    //     }
    //   }
    // },
    // '.MuiDivider-root': {
    //   borderColor: `${theme.palette.common.white}1A`
    // }
  },
  '& .nav-toggle-button': {
    position: `fixed`,
    left: drawerWidth,
    bottom: `71px`,
    zIndex: (theme) => theme.zIndex.drawer + 1,
    width: `26px`,
    height: `26px`,
    backgroundColor: theme.palette.primary[500],
    boxShadow: `0 3px 3px 0 rgba(0, 0, 0, 0.12)`,
    '&:hover': {
      backgroundColor: theme.palette.primary[700]
    },
    '&:active': {
      backgroundColor: theme.palette.primary[900]
    },
    transition: theme.transitions.create('left ', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    '& .MuiListItemButton-root': {
      justifyContent: 'initial'
    },
    '& .MuiListItemIcon-root': {
      marginRight: `10px`
    },
    '& .MuiListItemText-root': {
      opacity: 1
    },
    '& .project-setting-button ': {
      strong: {
        opacity: 1
      }
    },
    '& .back-to-console-button ': {
      strong: {
        opacity: 1
      }
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    '& .MuiListItemButton-root': {
      justifyContent: 'center',
      overflow: `hidden`
    },
    '& .MuiListItemIcon-root': {
      marginRight: `0`
    },
    '& .MuiListItemText-root': {
      opacity: 0,
      width: 0
    },
    '& .project-setting-button ': {
      minWidth: `40px`,
      strong: {
        width: 0,
        opacity: 0,
        margin: 0
      },
      '.MuiBox-root': {
        width: 0,
        opacity: 0
      }
    },
    '& .back-to-console-button ': {
      width: `40px`,
      minWidth: `40px`,
      padding: `9px`,
      margin: `0 auto`,
      strong: {
        width: 0,
        opacity: 0,
        margin: 0
      },
      '.MuiBox-root': {
        left: `9px`
      }
    },
    '& .nav-toggle-button': {
      position: `fixed`,
      left: `60px`,
      bottom: `71px`,
      width: `26px`,
      height: `26px`,
      zIndex: (theme) => theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.secondary[800],
      '&:hover': {
        backgroundColor: theme.palette.grayscaleSecondary[700]
      },
      '&:active': {
        backgroundColor: theme.palette.grayscaleSecondary[300]
      },
      transition: theme.transitions.create('left ', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  })
}));

export const Avatar = styled(MuiAvatar)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const List = styled(MuiList, {
  shouldForwardProp: (prop) => !['navOpen'].includes(String(prop))
})(({ theme, navOpen }) => ({
  padding: 0,
  '.MuiListItemIcon-root': {
    minWidth: `0`,
    marginRight: `12px`,
    color: theme.palette.secondary[800]
  },
  '.MuiDivider-root': {
    borderColor: theme.palette.divider
  },
  '.MuiListSubheader-root': {
    height: `16px`,
    position: `static`,
    lineHeight: `1.45`,
    backgroundColor: `transparent`,
    marginBottom: `8px`,
    paddingLeft: `12px`,
    fontSize: `11px`,
    fontWeight: `bold`,
    color: theme.palette.text.secondary,
    '&.minimize': {
      width: `16px`,
      height: `4px`,
      margin: `6px auto`,
      padding: 0,
      backgroundColor: theme.palette.text.secondary,
      lineHeight: `0`,
      fontSize: 0,
      borderRadius: `4px`
    },
    '+.MuiCollapse-root ': {
      // paddingBottom: `16px`,
      '.MuiListItemButton-root': {
        color: theme.palette.secondary[800],
        paddingLeft: `16px`,
        '&:before': {
          content: `none`
        },
        '&:hover': {
          backgroundColor: theme.palette.grayscaleSecondary[50]
        },
        '&.Mui-selected': {
          backgroundColor: alpha('#c2c8fb', 0.5),
          borderRadius: `3px`,
          '.MuiListItemIcon-root': {
            color: theme.palette.primary.main
          },
          '&:hover': {
            backgroundColor: alpha('#c2c8fb', 0.7)
          },
          '&:before': {
            opacity: `1`
          }
        }
      }
    }
  },
  '.MuiListItemButton-root': {
    position: `relative`,
    display: `flex`,
    width: `100%`,
    height: `40px`,
    marginBottom: `8px`,
    cursor: `pointer`,
    color: theme.palette.text.primary,
    '.MuiTypography-root': {
      fontSize: `14px`,
      fontWeight: 500
    },
    '&:hover': {
      backgroundColor: theme.palette.grayscaleSecondary[50]
    },
    '&.Mui-selected': {
      backgroundColor: alpha('#c2c8fb', 0.5),
      '.MuiListItemIcon-root': {
        color: theme.palette.primary.main
      },
      '&:hover': {
        backgroundColor: alpha('#c2c8fb', 0.7)
      },
      '&:before': {
        opacity: `1`
      }
    }
  },
  '.MuiCollapse-root': {
    '.MuiList-root': {
      padding: 0
    },
    '.MuiListItemButton-root': {
      height: `40px`,
      paddingLeft: `20px`,
      marginBottom: `2px`,
      '&:before': {
        content: '""',
        position: `static`,
        display: `block`,
        width: `6px`,
        height: `6px`,
        marginRight: `15px`,
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
        borderRadius: `100%`,
        opacity: `1`
      },
      '.MuiTypography-root': {
        fontSize: `13px`,
        fontWeight: `normal`,
        color: theme.palette.common.black
      },
      '&.Mui-selected': {
        backgroundColor: `transparent`,
        '&:before': {
          backgroundColor: theme.palette.primary.main
        }
      }
    }
  }
  // }),

  // ...(menutype === 'projects' && {
  //   margin: `0 10px`,
  //   padding: `0`,
  //   '> li': {
  //     marginBottom: `10px`,
  //     borderBottom: `1px solid ${theme.palette.grey[200]}`,
  //     '&:first-of-type': {
  //       borderBottom: `none`,
  //       paddingBottom: `10px`
  //     },
  //     '&:last-child': {
  //       paddingBottom: `10px`
  //     }
  //   },
  //   '.MuiList-root': {
  //     paddingTop: `10px`,
  //     paddingBottom: `10px`
  //   },
  //   '.MuiListSubheader-root': {
  //     position: `static`,
  //     lineHeight: `1`,
  //     padding: `0 9px`,
  //     fontSize: `11px`,
  //     color: theme.palette.text.secondary,
  //     fontWeight: `bold`
  //   },
  //   '.MuiListItemButton-root': {
  //     height: `40px`,
  //     borderRadius: `5px`,
  //     '&.Mui-selected': {
  //       color: theme.palette.primary.main,
  //       backgroundColor: theme.palette.grey[100],
  //       '.MuiListItemIcon-root': {
  //         color: theme.palette.primary.main
  //       },
  //       '&:hover': {
  //         backgroundColor: theme.palette.grey[200]
  //       },
  //       '&:before': {
  //         opacity: `1`
  //       }
  //     }
  //   }
  // })
}));

export const NavTitle = styled('div')(({ theme }) => ({
  height: `22px`,
  margin: `16px 12px 20px`,
  fontSize: `15px`,
  fontWeight: `bold`,
  textTransform: `uppercase`
}));

export const StyledSelectProject = styled('div')(({ theme }) => ({
  '.list': {
    position: `absolute`,
    borderRadius: `0 0 3px 3px`,
    backgroundColor: `#fff`,
    marginTop: `-1px`,
    left: theme.spacing(1.5),
    right: theme.spacing(1.5),
    zIndex: `2`,
    '.select2-selection__control': {
      margin: `10px`
    },
    '.select2-selection__indicators': {
      paddingRight: theme.spacing(1)
    },
    '.select2-selection__menu': {
      position: `static`,
      border: `none`,
      boxShadow: `none`
    },
    '.select2-selection__menu-list': {
      padding: `0`
    },
    '.select2-selection__option': {
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    '.custom-option': {
      display: `flex`,
      span: {
        flex: `1`,
        textOverflow: `ellipsis`,
        whiteSpace: `nowrap`,
        overflow: `hidden`
      }
    }
  }
}));

export const SelectProjectButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => !['isOpen', 'navOpen'].includes(String(prop))
})(({ theme, isOpen, navOpen }) => ({
  width: '100%',
  textAlign: 'left',
  padding: `12px`,
  borderRadius: `3px`,
  transition: theme.transitions.create('background-color ', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.shortest
  }),
  '.MuiBox-root': {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`
  },
  '&:hover': {
    boxShadow: ` 0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
    backgroundColor: theme.palette.common.white,
    '.MuiBox-root': {
      backgroundColor: `${theme.palette.secondary[800]}14`
    }
  },
  ...(isOpen && {
    borderRadius: `3px 3px 0 0`,
    boxShadow: `none`,
    backgroundColor: theme.palette.common.white
  }),
  ...(!navOpen && {
    padding: `8px`,
    pointerEvents: `none`,
    '.MuiGrid-root': {
      flexWrap: `nowrap`,
      '.MuiGrid-item': {
        '&:not(:has(.MuiAvatar-root))': {
          width: 0,
          opacity: 0
        }
      }
    }
  }),
  ...(navOpen && {
    '.MuiGrid-root': {
      flexWrap: `nowrap`,
      '.MuiGrid-item': {
        '&:not(:has(.MuiAvatar-root))': {
          width: `auto`,
          opacity: 1
        }
      }
    }
  })
}));

export const ProjectSettingButton = styled(Link)(({ theme }) => ({
  display: `inline-flex`,
  width: `100%`,
  height: `40px`,
  padding: `0 6px 0 14px`,
  justifyContent: `space-between`,
  alignItems: `center`,
  textTransform: `none`,
  textDecoration: `none`,
  boxSizing: `border-box`,
  borderRadius: `3px`,
  cursor: `pointer`,
  backgroundColor: theme.palette.grayscaleSecondary[50],
  '> div': {
    display: `inline-flex`,
    alignItems: `center`
  },
  strong: {
    display: `block`,
    fontWeight: `500`,
    marginLeft: `15px`
  },
  '.MuiBox-root': {
    marginRight: `9px`,
    transition: `all .2s linear`,
    '.MuiSvgIcon-root': {
      margin: `0 auto`
    }
  },
  '&:hover': {
    backgroundColor: `#c2c8feb3`,
    textDecoration: `none`,
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main
    },
    '.MuiBox-root': {
      margin: 0,
      backgroundColor: `#4b7de6`,
      '.MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText
      }
    }
  }
}));

export const BackToConsoleButton = styled(MuiButton)(({ theme }) => ({
  position: `relative`,
  display: `inline-flex`,
  width: `100%`,
  minWidth: `40px`,
  height: `40px`,
  padding: `0 6px 0 53px`,
  alignItems: `center`,
  textTransform: `none`,
  textDecoration: `none`,
  boxSizing: `border-box`,
  borderRadius: `3px`,
  backgroundColor: theme.palette.grayscaleSecondary[50],
  justifyContent: `space-between`,
  '> div': {
    display: `inline-flex`,
    alignItems: `center`
  },
  strong: {
    display: `block`,
    fontWeight: `500`
  },
  '.MuiBox-root': {
    position: `absolute`,
    left: `15px`,
    transition: `all .2s linear`,
    minWidth: `22px`,
    backgroundColor: theme.palette.secondary[800],
    '.MuiSvgIcon-root': {
      margin: `0 auto`
    }
  },
  '&:hover': {
    backgroundColor: `#c2c8feb3`,
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main
    },
    '.MuiBox-root': {
      left: `6px`,
      backgroundColor: `#4b7de6`,
      '.MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText
      }
    }
  }
}));
