import {useQuery} from 'react-query';
import {ApiFactory} from '@/utils/apiFactory';
import {exceptionProcessorForQuery} from '@/services/infrastructure/exceptionProcessorForQuery';

export const createKeyForListMySpaces = () => [`/my-spaces`];

export const useQueryToListMySpaces = (
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForListMySpaces(),
    async (context) => {
      try {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/users/me/spaces`,
          signal: context.signal
        });
        return response.data;
      } catch (error) {
        exceptionHandler.handle(error);
        throw error;
      }
    },
    {...options}
  );
