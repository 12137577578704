const stage = {
  domain: 'cloudplex.so',
  startApplicationUrl: 'https://start.demo.cloudplex.so',
  cpd: {
    apiV3: 'https://1gctdx4x5j.execute-api.ap-northeast-2.amazonaws.com/v3',
    channelV1: 'https://channel.demo.cloudplex.me/v1/receive'
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: 'PpL7CWFkHCMO08g1wZXUKQOOG0RrXw',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default stage;
