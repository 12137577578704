import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AppBar, Divider, Grid} from "@mui/material";
import {ImageLogoCloudplexDelivery} from "@mzc-cloudplex/icons";
import {Toolbar} from '@/components/layouts/header/header.styled';
import theme from "@/styles/theme";
import ProfileMenu from "@/components/layouts/header/ProfileMenu";
import {useQueryToGetMyInfo} from "@/hooks/queries/useQueryToGetMyInfo";

const HeaderForLayout = () => {
  const getLogoLink = (location) => {
    const cond = _.cond([
      [_.equals('/space'), _.identity],
      [_.T, _.always('/')]
    ]);
    return cond(_.get('pathname', location));
  };

  const location = useLocation();
  const {
    data: dataToGetMyInfo,
  } = useQueryToGetMyInfo();

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'common.white',
        color: theme.palette.secondary.main,
        boxShadow: `none`,
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
        zIndex: (props) => props.zIndex.drawer + 1
      }}
    >
      <Toolbar sx={{p: 0}}>
        <Grid container
              justifyContent="space-between"
              alignItems="center"
        >
          <Grid item xs pl={'16px'}>
            <h1>
              <Link to={getLogoLink(location)}>
                <ImageLogoCloudplexDelivery width={218} height={28}/>
              </Link>
            </h1>
          </Grid>
          <Divider orientation='vertical' flexItem/>
          <Grid item sx={{padding: `0 10px`}}>
            <ProfileMenu user={dataToGetMyInfo}/>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderForLayout;
