import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DEFAULT_LANGUAGE, SUPPORT_LOCALES} from '@/constants';
import {LanguageSelectButton, FooterWrapper} from "./footer.styled";
import {Grid, Divider, Menu, MenuItem, Typography} from "@mui/material";
import {IconArrowDropDown, IconArrowDropUp, IconLanguage} from "@mzc-cloudplex/icons";
import { Button } from '@mzc-cloudplex/core';
import theme from "@/styles/theme";


const Footer = (position) => {
  const {i18n, t} = useTranslation();
  const [showLanguageLayer, setShowLanguageLayer] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const localStorage = window.localStorage;

  useEffect(() => {
    setCurrentLanguage(localStorage.getItem('i18nextLang') || DEFAULT_LANGUAGE);
  }, []);

  const closeLanguageLayer = useCallback(() => {
    if (showLanguageLayer === true) {
      setShowLanguageLayer(false);
    }
  }, [showLanguageLayer]);

  const onChangeLanguage = _.curry((lng, e) => {
    _.isFunction(_.get('stopPropagation', e)) && e.stopPropagation();
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
    closeLanguageLayer();
    setAnchorEl(null);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openTermsPrivacy = useCallback(() => {
    window.open(`https://cloudplex.megazone.io/private_policy.html`);
  }, []);

  const openTermsService = useCallback(() => {
    window.open(`https://cloudplex.megazone.io/terms_conditions.html`);
  }, []);

  const openCustomerService = useCallback(() => {
    window.open(`https://help.megazone.io/`);
  }, []);


  return (
    <FooterWrapper
      container
      direction={`row`}
      justifyContent={`space-between`}
      alignItems={`center`}
      flexWrap={`nowrap`}
    >
      <Divider sx={{ margin: `0`, width: `auto`, height: `0` }} />
      <Grid item>
        <Grid container direction={`row`} alignItems={`center`}>
          <Grid item>
            <Button
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'secondary'}
              onClick={openTermsService}
            >
              {t(`Terms & Conditions`)}
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item>
            <Button
              className={'privacy'}
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'grayscaleSecondary'}
              onClick={openTermsPrivacy}
              sx={{
                fontWeight: 500,
              }}
            >
              {t(`Privacy policy`)}
            </Button>
          </Grid>
          <Grid item className={'divider'}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item className={'address'}>
            <address>
              ©{' '}
              <Typography component={`span`} variant={'body2'} color={theme.palette.primary.main}>
                MegazoneCloud Corp.
              </Typography>{' '}
              All rights reserved.
            </address>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={`row`} alignItems={`center`} flexWrap={`nowrap`}>
          <Grid item>
            <Button
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'secondary'}
              onClick={openCustomerService}
            >
              {t(`Customer Service`)}
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <LanguageSelectButton onClick={handleClick} active={open}>
            <IconLanguage size={16}/>
            {SUPPORT_LOCALES.map((v, i) => (
              <strong key={i}>
                {_.pipe(_.get('value'), _.split('-'), _.head, _.equals(currentLanguage))(v)
                  ? v.label
                  : ''}
              </strong>
            ))}
            {open ? <IconArrowDropDown size={7}/> : <IconArrowDropUp size={7}/>}
          </LanguageSelectButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                width: '150px',
              },
            }}
          >
            {SUPPORT_LOCALES.map((v, i) => (
              <MenuItem
                key={i}
                onClick={onChangeLanguage(_.pipe(_.get('value'), _.split('-'), _.head)(v))}
                selected={
                  _.pipe(_.get('value'), _.split('-'), _.head, _.equals(currentLanguage))(v)
                }
              >
                {v.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
    </FooterWrapper>
  );
};
export default Footer;
