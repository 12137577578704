const qa = {
  domain: 'cloudplex.so',
  startApplicationUrl: 'https://start.qa.cloudplex.so',
  cpd: {
    apiV3: 'https://sj5lwzcb6k.execute-api.ap-northeast-2.amazonaws.com/v3',
    channelV1: 'https://channel.qa.cloudplex.me/v1/receive'
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: 'IDKKWFTpmefstobJaJXHbm5mBOMYAW',
    clientSecret: 'j2teN1WgRfQc87mprKGVNEo4ca6n2s',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default qa;
