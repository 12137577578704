import React from 'react';
import {useTranslation} from 'react-i18next';
import {useToggle} from 'react-use';
import {useDeliveryLocation} from '@/hooks/useDeliveryLocation';
import {useQueryToGetProject} from '@/hooks/queries/useQueryToGetProject';
import {useInfiniteQueryToListMyProjects} from '@/hooks/queries/useInfiniteQueryToListMyProjects';
import {Avatar, SelectProjectButton, StyledSelectProject} from './styles/nav.styled';
import {
  IconArrowDropDown,
  IconArrowDropUp,
  IconCircleComplete,
  IconSearch
} from '@mzc-cloudplex/icons';
import theme from '@/styles/theme';
import {Grid, Tooltip, Typography, Skeleton} from '@mui/material';
import {Box} from '@mui/system';
import WithSelect from '@/components/common/selects/SelectForSingle';
import {useLocation, useNavigate} from "react-router-dom";
import {useQueryToListMySpaces} from "@/hooks/queries/useQueryToListMySpaces";
import menuService from "@/services/menuService";

const ProjectSelector = ({isExpanded}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useToggle(false);
  const {spaceId, projectId} = useDeliveryLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: dataToListMySpaces,
    isLoading: isLoadingToListMySpaces
  } = useQueryToListMySpaces();
  const {
    data: dataToGetActiveProject,
    isLoading: isLoadingToGetActiveProject
  } = useQueryToGetProject({
    spaceId: spaceId,
    projectId: projectId
  });
  const isLoading = isLoadingToGetActiveProject || isLoadingToListMySpaces;
  const activeProject = {
    label: dataToGetActiveProject?.name ?? '',
    value: dataToGetActiveProject?.id ?? ''
  };

  const {
    data: dataToListProjects,
    fetchNextPage: fetchNextPageToListProjects,
    hasNextPage: hasNextPageToListProjects,
    isFetchingNextPage: isFetchingNextPageToListProjects
  } = useInfiniteQueryToListMyProjects({spaceId: spaceId});

  const projectList = dataToListProjects?.pages
    .reduce((acc, cur) => [...acc, ...cur.results], [])
    .map((item) => ({label: item.name, value: item.id}));

  const onChangeProject = (item) => {
    const path = location.pathname;
    setIsOpen(false);
    navigate(menuService.changePathByProjectId(path, item.value));
    navigate(0);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={setIsOpen}
      target={
        <Tooltip title={activeProject?.label}>
          <SelectProjectButton
            onClick={setIsOpen}
            navOpen={isExpanded}
            isOpen={isOpen}
            className={`current ${isOpen ? 'active' : ''}`}
          >
            {isLoading ? (
              <Grid container alignItems={`center`}>
                <Grid item>
                  <Skeleton variant={'circular'} width={32} height={32}/>
                </Grid>
                <Grid item sx={{flex: `1`, ml: 1, overflow: `hidden`}}>
                  <Skeleton variant={'rectangular'} height={21}/>
                  <Skeleton variant={'rectangular'} height={14} sx={{marginTop: `2px`}}/>
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems={`center`}>
                <Grid item>
                  <Avatar
                    alt={activeProject?.label}
                    sx={{
                      width: `32px`,
                      height: `32px`,
                      marginRight: `0`,
                      fontSize: `16px`,
                      fontWeight: `bold`,
                      lineHeight: `initial`,
                      backgroundColor: `#4b7de6`
                    }}
                  >
                    {activeProject?.label?.slice(0, 1)}
                  </Avatar>
                </Grid>
                <Grid item sx={{flex: `1`, ml: 1, overflow: `hidden`}}>
                  <Typography
                    sx={{fontWeight: 'bold', fontSize: '14px'}}
                    noWrap
                    title={activeProject?.label}
                  >
                    {activeProject?.label}
                  </Typography>
                  <Typography variant={'caption'} color={theme.palette.text.secondary} noWrap>
                    {dataToListMySpaces?.results[0]?.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box width={24} height={24} borderRadius={5}>
                    {isOpen ? <IconArrowDropUp size={8}/> : <IconArrowDropDown size={8}/>}
                  </Box>
                </Grid>
              </Grid>
            )}
          </SelectProjectButton>
        </Tooltip>
      }
    >
      <WithSelect
        backspaceRemovesValue={false}
        components={{DropdownIndicator, IndicatorSeparator: null}}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onChangeProject}
        options={projectList}
        placeholder={t('label.Search for Projects')}
        tabSelectsValue={false}
        defaultOptions={activeProject}
        nextOptions={{
          hasNextOptions: hasNextPageToListProjects,
          listNextOptions: fetchNextPageToListProjects,
          isNextOptionsFetching: isFetchingNextPageToListProjects
        }}
        formatOptionLabel={(props) => {
          const selected = props.value === activeProject.value;
          return (
            <Tooltip title={props.label} placement={'left'}>
              <div className={`custom-option`}>
                <Avatar
                  alt={props.label.charAt(0)}
                  variant='rounded'
                  sx={{
                    width: `20px`,
                    height: `20px`,
                    fontSize: `14px`,
                    bgcolor: 'primary.main'
                  }}
                >
                  {props.label.charAt(0)}
                </Avatar>
                <span>{props.label}</span>
                {selected ? (
                  <IconCircleComplete size={16} htmlColor={theme.palette.primary[300]}/>
                ) : (
                  ''
                )}
              </div>
            </Tooltip>
          );
        }}
      />
    </Dropdown>
  );
};

export default ProjectSelector;

const Menu = (props) => <div className='list' {...props} />;

const Blanket = (props) => <div {...props} />;

const Dropdown = ({children, isOpen, target, onClose}) => (
  <StyledSelectProject>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? (
      <Blanket
        onClick={onClose}
        style={{
          bottom: 0,
          left: 0,
          top: 0,
          right: 0,
          position: 'fixed',
          zIndex: 1
        }}
      />
    ) : null}
  </StyledSelectProject>
);

const DropdownIndicator = () => <IconSearch size={16}/>;
