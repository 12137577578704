const menuService = {
  paths: {
    admin: {
      projects: (spaceId) => `/admin/spaces/${spaceId}/projects`,
      project: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}`,
      projectCreate: (spaceId) => `/admin/spaces/${spaceId}/project-create`,
      projectUser: (spaceId, projectId, userId) =>
        `/admin/spaces/${spaceId}/projects/${projectId}/users/${userId}`
    },
    summary: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/summary`,
    projectUsers: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/users`,
    projectUser: (spaceId, projectId, userId) =>
      `/spaces/${spaceId}/projects/${projectId}/users/${userId}`,
    dns: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/dns`,
    cdns: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cdns`,
    cdn: (spaceId, projectId, cdnId) => `/spaces/${spaceId}/projects/${projectId}/cdns/${cdnId}`,
    routingPolicies: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/routing-policies`,
    routingPolicy: (spaceId, projectId, routingPolicyId) =>
      `/spaces/${spaceId}/projects/${projectId}/routing-policies/${routingPolicyId}`,
    storage: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage`,
    jobs: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/jobs`,
    purgeJob: (spaceId, projectId, jobId) =>
      `/spaces/${spaceId}/projects/${projectId}/purge-jobs/${jobId}`,
    compareJob: (spaceId, projectId, jobId) =>
      `/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}`,
    notifications: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/notifications`,
    notification: (spaceId, projectId, notificationId) =>
      `/spaces/${spaceId}/projects/${projectId}/notifications/${notificationId}`,

    originStorage: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/origin-storage`,
    cdnMetrics: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cdn-metrics`,
    cdnMonitoring: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/cdn-monitoring`,

    projectHome: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}`,

    spaces: () => '/spaces',
    home: () => '/',

    welcome: () => '/welcome',
    invalidUser: () => '/invalid-user',
    browserNotSupported: () => '/error/browser'
  },
  rules: {
    admin: {
      projects: () => menuService.paths.admin.projects(':spaceId'),
      project: () => menuService.paths.admin.project(':spaceId', ':projectId'),
      projectCreate: () => menuService.paths.admin.projectCreate(':spaceId'),
      projectUser: () => menuService.paths.admin.projectUser(':spaceId', ':projectId', ':userId')
    },
    summary: () => menuService.paths.summary(':spaceId', ':projectId'),
    projectUsers: () => menuService.paths.projectUsers(':spaceId', ':projectId'),
    projectUser: () => menuService.paths.projectUser(':spaceId', ':projectId', ':userId'),
    dns: () => menuService.paths.dns(':spaceId', ':projectId'),
    cdns: () => menuService.paths.cdns(':spaceId', ':projectId'),
    cdn: () => menuService.paths.cdn(':spaceId', ':projectId', ':cdnId'),
    routingPolicies: () => menuService.paths.routingPolicies(':spaceId', ':projectId'),
    routingPolicy: () =>
      menuService.paths.routingPolicy(':spaceId', ':projectId', ':routingPolicyId'),
    storage: () => menuService.paths.storage(':spaceId', ':projectId'),
    jobs: () => menuService.paths.jobs(':spaceId', ':projectId'),
    purgeJob: () => menuService.paths.purgeJob(':spaceId', ':projectId', ':jobId'),
    compareJob: () => menuService.paths.compareJob(':spaceId', ':projectId', ':jobId'),
    notifications: () => menuService.paths.notifications(':spaceId', ':projectId'),
    notification: () => menuService.paths.notification(':spaceId', ':projectId', ':notificationId'),

    originStorage: () => menuService.paths.originStorage(':spaceId', ':projectId'),
    cdnMetrics: () => menuService.paths.cdnMetrics(':spaceId', ':projectId'),
    cdnMonitoring: () => menuService.paths.cdnMonitoring(':spaceId', ':projectId'),

    projectHome: () => menuService.paths.projectHome(':spaceId', ':projectId'),

    spaces: () => menuService.paths.spaces(),
    home: () => menuService.paths.home(),

    welcome: () => menuService.paths.welcome(),
    invalidUser: () => menuService.paths.invalidUser(),
    browserNotSupported: () => menuService.paths.browserNotSupported()
  },
  pagePermissions: {
    admin: {
      projects: ['project:list'],
      project: ['project:get'],
      projectCreate: ['project:create'],
      projectUser: ['project-user:list']
    },
    projectUsers: ['project-user:list'],
    projectUser: ['project-user:get'],
    dns: ['project/dns:get'],
    cdns: ['project/cdn:list'],
    cdn: ['project/cdn:get'],
    routingPolicies: ['project/routing-policy:list'],
    routingPolicy: ['project/routing-policy:get'],
    storage: ['project/storage:get'],
    jobs: ['project/purge-job:list', 'project/compare-job:list'],
    purgeJob: ['project/purge-job:get'],
    compareJob: ['project/compare-job:get'],
    notifications: ['project/notification:list'],
    notification: ['project/notification:get'],
    originStorage: ['project/drive:list'],
    cdnMetrics: ['project/cdn-metric:list'],
    cdnMonitoring: ['project/cdn-metric:list']
  },
  parse: (pathname) => {
    const paths = pathname.split('/'); // /spaces/123/projects/456 or /admin/spaces/123/projects/456
    if (paths.length > 1 && paths[1] === 'admin') {
      paths.splice(1, 1);
    }
    const spaceId = paths.length > 2 && paths[0] === '' && paths[1] === 'spaces' ? paths[2] : null;
    const projectId =
      spaceId != null && paths.length > 4 && paths[3] === 'projects' ? paths[4] : null;
    return { spaceId, projectId };
  },
  isConsole: (spaceId, projectId, path) => {
    const consolePaths = [
      menuService.paths.projectHome,
      menuService.paths.originStorage,
      menuService.paths.cdnMetrics,
      menuService.paths.cdnMonitoring
    ];

    for (const consolePath of consolePaths) {
      if (consolePath(spaceId, projectId) === path) {
        return true;
      }
    }
    return false;
  },
  changePathByProjectId: (path, projectId) => {
    // ToDO: 여기서 메뉴 구조를 유지하면서 잠시 들려서 이동 될 곳이 필요하다
    const paths = path.split('/').slice(0, 6); // ['', 'spaces', ':spaceId', 'projects', ':projectId', ...] 프로젝트 이동할 때 경로는 6뎁스 유지
    paths[4] = projectId;
    return paths.join('/');
  },
  permit: (currentPermissions, permissions) => {
    // currentPermissions = ['project/dns:get'];
    // currentPermissions = ['project/*'];
    // currentPermissions = ['*'];
    // console.log(currentPermissions);
    for (let currentPermission of currentPermissions) {
      if (currentPermission[currentPermission.length - 1] === '*') {
        if (
          permissions.some((permission) => permission.startsWith(currentPermission.slice(0, -1)))
        ) {
          return true;
        }
      } else {
        if (permissions.includes(currentPermission)) {
          return true;
        }
      }
    }
    return false;
  }
};

export default menuService;
