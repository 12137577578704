import { styled } from '@mui/system';
import theme from '@/styles/theme';

export const headerHeight = theme.mixins.toolbar.minHeight;
export const footerHeight = `48px`;
export const footerTabletHeight = `102px`;
export const footerMobileHeight = `165px`;
export const panelWidth = `240px`;

export const Documents = styled('div')(() => ({
  width: `100%`,
  minHeight: `100%`,
  height: `100%`,
  overflow: `hidden`,
  display: `flex`,
  backgroundColor: theme.palette.common.white
}));

export const Container = styled('div')(({ theme, open }) => ({
  flex: 1,
  boxSizing: `border-box`,
  height: `100%`,
  minWidth: 0,
  overflow: `hidden`,
  display: `flex`,
  flexDirection: `column`,
  width: `calc(100vw - ${panelWidth})`,
  position: `relative`,
  zIndex: `1`
}));

export const Content = styled('div')(() => ({
  flex: 1,
  display: `flex`,
  flexDirection: `column`,
  flexWrap: `nowrap`,
  justifyContent: `flex-start`,
  alignItems: `stretch`,
  boxSizing: `border-box`,
  height: `100%`,
  minWidth: 0,
  padding: `16px 40px 0 40px`,
  overflow: `scroll`

  // ["@container (min-width: 700px)"]: {
  //   // backgroundColor: theme.palette.background.paper,
  // },
}));

export const EmptyContent = styled('div')(() => ({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  flexWrap: `wrap`,
  maxWidth: `1280px`,
  height: `calc(100vh - ${headerHeight}px - ${footerHeight})`,
  margin: `${headerHeight}px auto 0`,
  textAlign: `center`
  // [theme.breakpoints.down('xl')]: {
  //   minHeight: `calc(100vh - ${footerTabletHeight})`,
  //   margin: `0 auto -${footerTabletHeight}`,
  //   padding: `32px 0 ${footerTabletHeight}`,
  // },
  // [theme.breakpoints.down('md')]: {
  //   minHeight: `calc(100vh - ${footerMobileHeight})`,
  //   margin: `0 auto -${footerMobileHeight}`,
  //   padding: `32px 0 ${footerMobileHeight}`
  // },
}));
