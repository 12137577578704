import getByteLength from './getByteLength';

const validateRequired = (value, rule) => {
  if (rule === true && (!value || value.trim().length === 0)) {
    return {
      type: 'required'
    };
  }
  return null;
};

const validatePattern = (value, rule) => {
  if (!new RegExp(rule).test(value)) {
    return {
      type: 'pattern',
      level: 'error',
      message: 'This field is invalid'
    };
  }
  return null;
};

const validateMaxByte = (value, rule) => {
  if (rule && rule > -1 && getByteLength(value, rule) > rule) {
    return {
      type: 'maxByte',
      level: 'error',
      message: `max byte error message`,
      value: value,
      rule: rule
    };
  }

  return null;
};

const validateUniqueIn = (value, rule) => {
  if (rule.length > 0 && rule.filter((v) => v && v === value).length >= 1) {
    return {
      type: 'uniqueIn',
      level: 'error',
      message: 'Duplicate values. This field must be unique.'
    };
  }

  return null;
};

const validateUrl = (value, rule) => {
  if (
    rule === true
    && !(/^[a-z0-9.-]{2,127}[a-z0-9]$/g.test(value) && _.getOr(0, 'length', value.match(/\./g)) > 0)
  ) {
    return {
      type: 'url',
      level: 'error',
      message: 'Domain is invalid'
    };
  }
  return null;
};

const validateFullUrl = (value, rule) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' // validate protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
      + '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator

  if (rule === true && !urlPattern.test(value)) {
    return {
      type: 'fullUrl',
      level: 'error',
      message: 'URL is invalid'
    };
  }
  return null;
};

const validateUrlPrefix = (value, rule) => {
  if (rule === true && !/^([a-z0-9][a-z0-9-_]*\.)*[a-z0-9]*[a-z0-9-_]*[[a-z0-9]+$/g.test(value)) {
    return {
      type: 'url',
      level: 'error',
      message: 'Domain is invalid'
    };
  }
  return null;
};

const validateOriginPath = (value, rule) => {
  if (rule === true && !_.isEmpty(value) && !/^.*.\/$/gi.test(value)) {
    return {
      type: 'IS_VALID_ORIGIN_PATH',
      level: 'error',
      message: 'Origin path is invalid'
    };
  }

  return null;
};

const validateSame = (value, rule) => {
  if (rule && !_.isEmpty(value) && value !== rule) {
    return {
      type: 'same',
      level: 'error',
      message: `project name is incorrect.` // ToDO: 이거는 범용적인 메시지로 변경 해야함
    };
  }

  return null;
};

const validateFolder = (value, rule) => {
  if (rule && !_.isEmpty(value) && value.includes('*')) {
    return {
      type: 'folder',
      level: 'error',
      message: `folder rule error message`,
      value: value,
      rule: rule
    };
  }

  return null;
};

const validateFilePath = (value, rule) => {
  //if (rule && !_.isEmpty(value) && !/^([^\x80-\xff\\{}"^|%`\]>[~<#*])+$/gi.test(value)) {
  if (
    rule
    && !_.isEmpty(value)
    && (value.includes('*') || value.includes(':') || value.includes('/'))
  ) {
    return {
      type: 'file',
      level: 'error',
      message: `file rule error message`,
      value: value,
      rule: rule
    };
  }

  return null;
};

const validateAlias = (value, rule) => {
  if (rule && !/^[a-z0-9]{0,16}$/.test(value)) {
    return {
      type: 'alias',
      level: 'error',
      message: 'The Alias name can be up to 16 letters, consisting of lowercase letters and numbers'
    };
  }

  return null;
};

const validateEndpoint = (value, rule) => {
  if (
    rule
    && !new RegExp('^([0-9a-z!"#$%&\'()*+,\\-/:;<=>?@\\[\\\\\\]^_`{|}]+\\.)+([a-z]{2,})$').test(
      value
    )
  ) {
    return {
      type: 'endpoint',
      level: 'error',
      message: 'Endpoint is invalid'
    };
  }

  return null;
};

const validateIpV4 = (value, rule) => {
  if (
    rule
    && !/^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm.test(
      value
    )
  ) {
    return {
      type: 'ipV4',
      level: 'error',
      message: 'IP V4 is invalid'
    };
  }

  return null;
};

const validateIpV6 = (value, rule) => {
  if (
    rule
    && !/^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(
      value
    )
  ) {
    return {
      type: 'ipV6',
      level: 'error',
      message: 'IP V6 is invalid'
    };
  }

  return null;
};

const validateEmail = (value, rule) => {
  if (
    rule
    && !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(
      value
    )
  ) {
    return {
      type: 'email',
      level: 'error',
      message: 'Email is invalid'
    };
  }
};

const validators = {
  required: validateRequired,
  pattern: validatePattern,
  maxByte: validateMaxByte,
  uniqueIn: validateUniqueIn,
  url: validateUrl,
  fullUrl: validateFullUrl,
  urlPrefix: validateUrlPrefix,
  originPath: validateOriginPath,
  same: validateSame,
  folder: validateFolder,
  filePath: validateFilePath,
  alias: validateAlias,
  endpoint: validateEndpoint,
  ipV4: validateIpV4,
  ipV6: validateIpV6,
  email: validateEmail
};

const getValidatorsByRules = (rules) =>
  Object.keys(rules)
    .filter((key) => rules[key])
    .map((key) => (value) => validators[key](value, rules[key]));

function validateInput(value, rules) {
  for (let validator of getValidatorsByRules(rules)) {
    const result = validator(value);
    if (result != null) {
      return result;
    }
  }

  return null;
}

export default validateInput;
