import React, {lazy} from 'react';
import {detect} from 'detect-browser';
import config from '@/config';
import WithRouteGuard from '@/routes/WithRouteGuard';
import menuService from "@/services/menuService";

const Login = lazy(() => import('@/routes/Login'));
const InvalidUser = lazy(() => import('@/routes/errors/InvalidUser'));
const NotSupportBrowser = lazy(() => import('@/routes/errors/NotSupportBrowser'));

const Welcome = lazy(() => import('@/routes/welcome/Welcome'));

const Stage = lazy(() => import('@/routes/Stage'));

const OriginBrowser = lazy(() => import('@/routes/console/OriginBrowser'));
const CdnMetrics = lazy(() => import('@/routes/console/CdnMetrics'));
const CdnMonitoring = lazy(() => import('@/routes/console/CdnMonitoring'));

//project manager
const Summary = lazy(() => import('@/routes/setting/Summary'));
const UserList = lazy(() => import('@/routes/setting/Users'));
const UserDetail = lazy(() => import('@/routes/setting/User'));
const Notifications = lazy(() => import('@/routes/setting/Notifications'));
const Notification = lazy(() => import('@/routes/setting/Notification'));
const StorageDetail = lazy(() => import('@/routes/setting/Storage'));
const DnsDetail = lazy(() => import('@/routes/setting/Dns'));
const CdnList = lazy(() => import('@/routes/setting/Cdns'));
const CdnDetail = lazy(() => import('@/routes/setting/Cdn'));
const RoutingPolicyList = lazy(() => import('@/routes/setting/RoutingPolicies'));
const RoutingPolicyDetail = lazy(() => import('@/routes/setting/RoutingPolicy'));
const JobList = lazy(() => import('@/routes/setting/Jobs'));
const PurgeJobDetail = lazy(() => import('@/routes/setting/PurgeJob'));
const CompareJobDetail = lazy(() => import('@/routes/setting/CompareJob'));

//administration
const Projects = lazy(() => import('@/routes/administration/ProjectList'));
const ProjectCreate = lazy(() => import('@/routes/administration/ProjectCreate'));
const ProjectDetail = lazy(() => import('@/routes/administration/ProjectDetail.jsx'));
const ProjectUserDetail = lazy(() => import('@/routes/administration/ProjectUserDetail'));

const browser = detect();

const getBrowserName = (browser) => {
  const composer = _.pipe(
    _.getOr('unkown browser', 'name'),
    _.cond([
      [_.equals('ie'), () => 'Internet Explorer'],
      [_.T, _.identity]
    ])
  );
  return composer(browser);
};

export const getPublicRoutes = () => [
  {
    name: 'welcome',
    path: menuService.rules.welcome(),
    element: (<Welcome/>)
  },
  {
    name: 'login',
    path: config.pops.redirectUrl,
    element: (<Login/>)
  },
  {
    name: 'login',
    path: config.pops.initiateLoginUrl,
    element: (<Login/>)
  },
  {
    name: 'invalid-user',
    path: menuService.rules.invalidUser(),
    element: (<InvalidUser/>)
  },
  {
    name: 'notSupportBrowser',
    path: menuService.rules.browserNotSupported(),
    element: (<NotSupportBrowser browserName={getBrowserName(browser)}/>)
  }
];

export const getAuthRoutes = () => [
  {
    name: 'Home',
    path: menuService.rules.home(),
    element: <WithRouteGuard><Stage/></WithRouteGuard>
  },
  {
    name: 'Spaces',
    path: menuService.rules.spaces(),
    element: <WithRouteGuard><Stage/></WithRouteGuard>
  },
  {
    name: 'Project create',
    path: menuService.rules.admin.projectCreate(),
    element: <WithRouteGuard><ProjectCreate/></WithRouteGuard>
  },
  {
    name: 'Projects',
    path: menuService.rules.admin.projects(),
    element: <WithRouteGuard><Projects/></WithRouteGuard>
  },
  {
    name: 'Project detail',
    path: menuService.rules.admin.project(),
    element: <WithRouteGuard><ProjectDetail/></WithRouteGuard>
  },
  {
    name: 'Project user detail',
    path: menuService.rules.admin.projectUser(),
    element: <WithRouteGuard><ProjectUserDetail/></WithRouteGuard>
  },
  {
    name: 'Project home',
    path: menuService.rules.projectHome(),
    element: <WithRouteGuard><Summary/></WithRouteGuard>
  },
  {
    name: 'Summary',
    path: menuService.rules.summary(),
    element: <WithRouteGuard><Summary/></WithRouteGuard>
  },
  {
    name: 'Project users',
    path: menuService.rules.projectUsers(),
    element: <WithRouteGuard><UserList/></WithRouteGuard>
  },
  {
    name: 'Project user Detail',
    path: menuService.rules.projectUser(),
    element: <WithRouteGuard><UserDetail/></WithRouteGuard>
  },
  {
    name: 'Notifications',
    path: menuService.rules.notifications(),
    element: <WithRouteGuard><Notifications/></WithRouteGuard>
  },
  {
    name: 'Notification detail',
    path: menuService.rules.notification(),
    element: <WithRouteGuard><Notification/></WithRouteGuard>
  },
  {
    name: 'Storage detail',
    path: menuService.rules.storage(),
    element: <WithRouteGuard><StorageDetail/></WithRouteGuard>
  },
  {
    name: 'DNS detail',
    path: menuService.rules.dns(),
    element: <WithRouteGuard><DnsDetail/></WithRouteGuard>
  },
  {
    name: 'CDNs',
    path: menuService.rules.cdns(),
    element: <WithRouteGuard><CdnList/></WithRouteGuard>
  },
  {
    name: 'CDN detail',
    path: menuService.rules.cdn(),
    element: <WithRouteGuard><CdnDetail/></WithRouteGuard>
  },
  {
    name: 'Routing policies',
    path: menuService.rules.routingPolicies(),
    element: <WithRouteGuard><RoutingPolicyList/></WithRouteGuard>
  },
  {
    name: 'Routing policy detail',
    path: menuService.rules.routingPolicy(),
    element: <WithRouteGuard><RoutingPolicyDetail/></WithRouteGuard>
  },
  {
    name: 'Jobs',
    path: menuService.rules.jobs(),
    element: <WithRouteGuard><JobList/></WithRouteGuard>
  },
  {
    name: 'Purge job detail',
    path: menuService.rules.purgeJob(),
    element: <WithRouteGuard><PurgeJobDetail/></WithRouteGuard>
  },
  {
    name: 'Compare job detail',
    path: menuService.rules.compareJob(),
    element: <WithRouteGuard><CompareJobDetail/></WithRouteGuard>
  },
  {
    name: 'Origin Storage',
    path: menuService.rules.originStorage(),
    element: <WithRouteGuard><OriginBrowser/></WithRouteGuard>
  },
  {
    name: 'CDN Metrics',
    path: menuService.rules.cdnMetrics(),
    element: <WithRouteGuard><CdnMetrics/></WithRouteGuard>
  },
  {
    name: 'CDN Monitoring',
    path: menuService.rules.cdnMonitoring(),
    element: <WithRouteGuard><CdnMonitoring/></WithRouteGuard>
  }
];

const routes = [...getPublicRoutes(), ...getAuthRoutes()];
export default routes;
