import validateInput from '@/utils/validateInput';

export const isEmpty = (target) => {
  if (target === undefined || target === null) {
    return true;
  }
  if (target === '') {
    return true;
  }
  if (Array.isArray(target)) {
    return target.length === 0;
  }
  if (typeof target === 'object') {
    return Object.keys(target) < 1;
  }
  return !!!target;
};

export const isAllNotEmpty = (obj) => {
  let isAllNotEmpty = true;

  Object.keys(obj).forEach((item) => {
    if (isEmpty(obj[item])) {
      isAllNotEmpty = false;
    }
  });

  return isAllNotEmpty;
};

export const getTimeOptions = (type) => {
  const cond = _.cond([
    [_.equals('hour'), () => range(0, 24, 1)],
    [_.equals('5minute'), () => range(0, 60, 5)],
    [_.equals('minute'), () => range(0, 60, 1)]
  ]);
  const padZero = (a) => (_.toNumber(a) < 10 ? `0${a}` : _.toString(a));
  const composer = _.pipe(
    cond,
    _.map((n) => ({
      label: padZero(n),
      value: padZero(n)
    }))
  );
  const result = composer(type);

  return result;
};

const range = (start, end, step) => {
  let arr = [],
    len = 0;

  step = step === undefined ? 1 : step;
  start = start === undefined ? 1 : start;
  end = end === undefined ? 1 : end;
  len = end / step;

  let i = 0;
  while (i < len) {
    arr.push(start + i * step);

    i += 1;
  }

  return arr;
};

export const validateInputValue = (key, value, rule, validationErrors, setValidationErrors) => {
  const validationState = getValidationErrorState(validationErrors, {
    key: key,
    error: validateInput(value, rule)
  });

  setValidationErrors(validationState);

  return validationState;
};

const getValidationErrorState = (validationErrors, { key, error }) => {
  if (error) {
    return _.set(key, error, validationErrors);
  } else if (validationErrors[key]) {
    return _.unset(key, validationErrors);
  }

  return validationErrors;
};
