import menuService from '@/services/menuService';
import {
  IconAnalytics,
  IconCdn,
  IconDashboard,
  IconDns,
  IconJobs,
  IconNotice,
  IconRoute,
  IconServer,
  IconStorage,
  IconSummary,
  IconUser
} from '@mzc-cloudplex/icons';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryToGetMyInfoInProject } from '@/hooks/queries/useQueryToGetMyInfoInProject';

const useMenu = ({ spaceId, projectId }) => {
  const location = useLocation();

  const { data: dataToGetMyInfoInProject } = useQueryToGetMyInfoInProject({ spaceId, projectId });
  const permissions = dataToGetMyInfoInProject?.permissions || [];
  const menus = [
    {
      id: 'Summary',
      name: `Summary`,
      path: menuService.paths.summary(spaceId, projectId),
      iconClass: 'sprite sprite-menu-setting',
      icon: <IconSummary size={16} />,
      disabled: false,
      active: true,
      isSelect: () =>
        location.pathname.includes(menuService.paths.summary(spaceId, projectId))
        || menuService.paths.projectHome(spaceId, projectId) === location.pathname,
      isVisible: () => true
    },
    {
      id: 'Access',
      name: `Access`,
      path: '',
      divider: true,
      active: false,
      isSelect: () => false,
      isVisible: () => menuService.permit(permissions, menuService.pagePermissions.projectUsers),
      subMenus: [
        {
          id: 'users',
          name: `Users`,
          path: menuService.paths.projectUsers(spaceId, projectId),
          iconClass: 'sprite sprite-menu-user',
          icon: <IconUser size={16} />,
          disabled: false,
          active: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.projectUsers(spaceId, projectId)),
          isVisible: () => menuService.permit(permissions, menuService.pagePermissions.projectUsers)
        }
      ]
    },
    {
      id: 'Manage',
      name: `Manage`,
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () =>
        menuService.permit(permissions, [
          ...menuService.pagePermissions.dns,
          ...menuService.pagePermissions.cdns,
          ...menuService.pagePermissions.routingPolicies,
          ...menuService.pagePermissions.storage,
          ...menuService.pagePermissions.jobs,
          ...menuService.pagePermissions.notifications
        ]),
      subMenus: [
        {
          id: 'DNS',
          name: `DNS`,
          path: menuService.paths.dns(spaceId, projectId),
          iconClass: 'sprite sprite-menu-dns',
          icon: <IconDns size={16} />,
          disabled: false,
          active: true,
          isSelect: () => location.pathname.includes(menuService.paths.dns(spaceId, projectId)),
          isVisible: () => menuService.permit(permissions, menuService.pagePermissions.dns)
        },
        {
          id: 'CDN(s)',
          name: `CDN(s)`,
          path: menuService.paths.cdns(spaceId, projectId),
          iconClass: 'sprite sprite-menu-cdn',
          icon: <IconCdn size={16} />,
          disabled: false,
          active: true,
          isSelect: () => location.pathname.includes(menuService.paths.cdns(spaceId, projectId)),
          isVisible: () => menuService.permit(permissions, menuService.pagePermissions.cdns)
        },
        {
          id: 'Routing',
          name: `Routing Policy`,
          path: menuService.paths.routingPolicies(spaceId, projectId),
          iconClass: 'sprite sprite-menu-route',
          icon: <IconRoute size={16} />,
          disabled: false,
          active: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.routingPolicies(spaceId, projectId)),
          isVisible: () =>
            menuService.permit(permissions, menuService.pagePermissions.routingPolicies)
        },
        {
          id: 'Storage',
          name: `Storage`,
          path: menuService.paths.storage(spaceId, projectId),
          iconClass: 'sprite sprite-menu-storage',
          icon: <IconStorage size={16} />,
          disabled: false,
          active: true,
          isSelect: () => location.pathname.includes(menuService.paths.storage(spaceId, projectId)),
          isVisible: () => menuService.permit(permissions, menuService.pagePermissions.storage)
        },
        {
          id: 'jobs',
          name: `Jobs`,
          path: menuService.paths.jobs(spaceId, projectId),
          iconClass: 'sprite sprite-menu-setting',
          icon: <IconJobs size={16} />,
          disabled: false,
          active: true,
          divider: true,
          // supportedEnvironments: ['development'],
          isSelect: () => location.pathname.includes(menuService.paths.jobs(spaceId, projectId)),
          isVisible: () => menuService.permit(permissions, menuService.pagePermissions.jobs)
        },
        {
          id: 'notifications',
          name: `Notifications`,
          path: menuService.paths.notifications(spaceId, projectId),
          iconClass: 'sprite sprite-menu-setting',
          icon: <IconNotice size={16} />,
          disabled: false,
          active: true,
          divider: true,
          // supportedEnvironments: ['development'],
          isSelect: () =>
            location.pathname.includes(menuService.paths.notifications(spaceId, projectId)),
          isVisible: () =>
            menuService.permit(permissions, menuService.pagePermissions.notifications)
        }
      ]
    },
    {
      id: 'Origin',
      name: `Origin`,
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () => menuService.permit(permissions, menuService.pagePermissions.originStorage),
      subMenus: [
        {
          id: 'origin',
          name: 'Origin Storage',
          path: menuService.paths.originStorage(spaceId, projectId),
          iconClass: 'sprite sprite-menu-origin-storage',
          icon: <IconServer size={16} />,
          disabled: true,
          active: false,
          isSelect: () =>
            location.pathname.includes(menuService.paths.originStorage(spaceId, projectId)),
          isVisible: () =>
            menuService.permit(permissions, menuService.pagePermissions.originStorage)
        }
      ]
    },
    {
      id: 'Monitoring',
      name: `Monitoring`,
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () =>
        menuService.permit(permissions, [
          ...menuService.pagePermissions.cdnMetrics,
          ...menuService.pagePermissions.cdnMonitoring
        ]),
      subMenus: [
        {
          id: 'cdn-metrics',
          name: 'CDN Metrics',
          path: menuService.paths.cdnMetrics(spaceId, projectId),
          icon: <IconAnalytics size={16} />,
          disabled: true,
          active: false,
          isSelect: () =>
            location.pathname.includes(menuService.paths.cdnMetrics(spaceId, projectId)),
          isVisible: () => menuService.permit(permissions, menuService.pagePermissions.cdnMetrics)
        },
        {
          id: 'cdn-monitoring',
          name: 'CDN Monitoring',
          path: menuService.paths.cdnMonitoring(spaceId, projectId),
          iconClass: 'sprite sprite-menu-origin-storage',
          icon: <IconDashboard size={16} />,
          disabled: true,
          active: false,
          isSelect: () =>
            location.pathname.includes(menuService.paths.cdnMonitoring(spaceId, projectId)),
          isVisible: () =>
            menuService.permit(permissions, menuService.pagePermissions.cdnMonitoring)
        }
      ]
    }
  ];

  return {
    menus: menus
  };
};

export default useMenu;
