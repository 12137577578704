import React, {lazy, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Toolbar} from "@mui/material";
import {Container, Content, Documents} from "@/components/layouts/layout.styled";
import styleTheme from '@/styles/theme';
const JobListContainer = lazy(() => import("@/components/myJob/JobListContainer"));
const Footer = lazy(() => import('@/components/layouts/footer/Footer'));
const Header = lazy(() => import('@/components/layouts/header/Header'));
const Navigator = lazy(() => import('@/components/navigation/Navigator'));

const Layout = ({children}) => {
  const [isJobPanelOpen, setJobPanelOpen] = useState(false);
  const [isExistedNewJob, setExistedNewJob]= useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      <Documents theme={styleTheme}>
        <Header
          isJobPanelOpen={isJobPanelOpen}
          isExistedNewJob={isExistedNewJob}
          openJobPanel={() => setJobPanelOpen(true)}
          closeJobPanel={() => setJobPanelOpen(false)}
        />
        <Navigator/>
        <Container>
          <Toolbar/>
          <Content>
            {children}
          </Content>
          <JobListContainer
            isJobPanelOpen={isJobPanelOpen}
            setExistedNewJob={setExistedNewJob}
            closeJobPanel={() => setJobPanelOpen(false)}
          />
          <Footer/>
        </Container>
      </Documents>
    </DndProvider>
  );
};

export default Layout;
