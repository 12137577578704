export const badRequestForDelivery = (error) => {
  if (error?.response?.status === 400 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items?.map((item) => {
      const message = badRequestMessages[item.code];
      if (message != null) {
        return {
          type: 'error',
          code: item.code,
          message: item.message,
          translation: {
            key: message ?? item.message
          }
        };
      } else {
        return {
          type: 'error',
          code: item.code,
          message: item.message,
          translation: {
            key: 'Invalid PROPERTY',
            property: item.code
          }
        };
      }
    });
  }
};

const badRequestMessages = {
  EMPTY_SORT_STRING: 'A sorting condition is required',
  ILLEGAL_SORT_STRING: 'Unknown sort condition',
  ILLEGAL_PLATFORM: 'Unknown platform',
  ILLEGAL_SERVICE: 'Unknown service',
  MODEL_PARSE_ERROR: 'Bad request',
  INVALID_ID: 'Invalid ID',
  APPLIED_DNS_RECORD: 'Failed to apply DNS record',
  NOT_SUPPORTED_CLOUD_PRODUCT: 'The requested product is not supported',
  NOT_SUPPORTED_OFFSET_PAGINATION: 'Offset pagination is not supported',
  'DNS.ALIAS': 'The Alias name can be up to 16 letters, consisting of lowercase letters and numbers'
};

export const notFoundForDelivery = (error) => {
  if (error?.response?.status === 404 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items
      ?.filter((item) => notFoundMessages[item.code] != null)
      ?.map((item) => ({
        type: 'error',
        code: item.code,
        message: item.message,
        translation: {
          key: notFoundMessages[item.code] ?? item.message
        }
      }));
  }
};

const notFoundMessages = {
  DNS_NOT_FOUND: 'DNS not found',
  PROJECT_NOT_FOUND: 'Project not found',
  STORAGE_NOT_FOUND: 'Storage not found',
  USER_NOT_FOUND: 'User not found'
};

export const conflictForDelivery = (error) => {
  if (error?.response?.status === 409 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items
      ?.filter((item) => conflictMessages[item.code] != null)
      ?.map((item) => ({
        type: 'error',
        code: item.code,
        message: item.message,
        translation: {
          key: conflictMessages[item.code] ?? item.message
        }
      }));
  }
};

const conflictMessages = {
  ACTIVE_ROUTING_POLICY: 'This is the active routing policy',
  CDN_IN_ROUTING_POLICY: 'I have a CDN that I use in routing policy',
  CREDENTIAL_PERMISSION: 'Please contact CloudPlex representative',
  DNS_IN_ROUTING_POLICY: 'A routing policy exists in DNS',
  DUPLICATED_DNS_INFO: 'I already have a DNS with the same information',
  DUPLICATED_DNS_ZONE: 'I already have a DNS with the same zone',
  DUPLICATED_ROUTING_POLICY_ALIAS: 'There is an alias with the same name in the routing policy',
  EXISTED_DNS: 'This project already has DNS',
  NOT_EXIST_SOURCE: 'No target.',
  DUPLICATED_CUSTOM_CDN: 'I already have a custom CDN with the same information',
  MAXIMUM_CUSTOM_CDN_COUNT_EXCEEDED: 'You can only create up to 50 Custom CDNs'
};

export const serviceUnavailableForDelivery = (error) => {
  if (error?.response?.status === 503 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items
      ?.filter((item) => serviceUnavailableMessages[item.code] != null)
      ?.map((item) => ({
        type: 'error',
        code: item.code,
        message: item.message,
        translation: {
          key: serviceUnavailableMessages[item.code] ?? item.message
        }
      }));
  }
};

const serviceUnavailableMessages = {
  INVALID_CREDENTIAL: 'Please try again in a few minutes',
  INVALID_DNS_RECORD: 'Please try again in a few minutes',
  INVALID_SCHEDULE_REGISTERED: 'Please try again in a few minutes'
};
