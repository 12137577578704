import {useQuery} from 'react-query';
import {ApiFactory} from '@/utils/apiFactory';
import {exceptionProcessorForQuery} from '@/services/infrastructure/exceptionProcessorForQuery';

export const createKeyForGetProject = ({spaceId, projectId}) => [`/spaces/${spaceId}/projects/${projectId}`];
export const useQueryToGetProject = (
  {spaceId, projectId},
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) => useQuery(
  createKeyForGetProject({spaceId, projectId}),
  async (context) => {
    try {
      const response = await ApiFactory.cpdV3({
        method: 'get',
        url: `/projects/me`,
        headers: {
          'cloudplex-space-id': spaceId,
          'cloudplex-project-id': projectId,
        },
        signal: context.signal
      });
      return response.data;
    } catch (error) {
      exceptionHandler.handle(error);
      throw error;
    }
  },
  {...options}
);
